(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/poker-tournament-hero/assets/javascripts/poker-tournament-hero.js') >= 0) return;  svs.modules.push('/widgets/poker-tournament-hero/assets/javascripts/poker-tournament-hero.js');
"use strict";


const {
  trackingEvents
} = svs.components.poker.common.constants;
const {
  startPokerTwister,
  launchTwisterFromStorage,
  trackPokerTwisterEvent
} = svs.components.poker.common.util;
const setupEventListeners = options => {
  const elements = options.$el.find('.poker-btn');
  for (let i = 0; i < elements.length; i++) {
    const el = elements[i];
    el.addEventListener('click', () => {
      const gameCodeName = el.attributes['data-gamecodename'].value;
      if (gameCodeName) {
        trackPokerTwisterEvent({
          trackingType: trackingEvents.TRACK_EVENT,
          opt_label: gameCodeName
        });
        startPokerTwister(gameCodeName);
      }
    });
  }
};
const init = options => {
  launchTwisterFromStorage();
  setupEventListeners(options);
};
setGlobal('svs.widget.poker_tournament_hero.init', options => {
  if (options && options.model) {
    init(options);
  }
});

 })(window);